import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useStaticQuery } from "gatsby";
import "../../scss/main.scss";
import {
	Newsblock,
	NewsblockSection,
	NewsblockContent,
	NewsblockBlocks,
	NewsblockTitle,
} from '../components/Newsblock';

export default function Blog() {
	const { relatedPosts: { nodes: newsblockDataUnsorted }  } = useStaticQuery(
		graphql`
			query {
				relatedPosts: allGhostPost(filter: {slug: {
					regex: "/what-can-a-live-in-carer-help-with|live-in-care-vs-care-home|what-is-the-cost-of-live-in-care/"}
				}) {
					nodes {
						title
						slug
					}
				}
			}
		`)
	const newsblockData = [
		newsblockDataUnsorted.find(post => post.slug === 'what-can-a-live-in-carer-help-with'),
		newsblockDataUnsorted.find(post => post.slug === 'live-in-care-vs-care-home'),
		newsblockDataUnsorted.find(post => post.slug === 'what-is-the-cost-of-live-in-care')
	]
	return (
		<>
			<Helmet>
				<meta charset="UTF-8" />
				<meta http-equiv="X-UA-Compatible" content="IE=edge" />
				
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>Edyn Care | Blog</title>
				<meta property="og:title" content="Edyn Care | Blog"/>
				<meta property="og:description" content="High quality care in the comfort of your own home."/>
				<meta property="og:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png"/>
				<meta property="og:url" content="https://www.edyn.care/blog" />
				<meta name="twitter:card" content="summary_large_image" />
				<meta property="twitter:domain" content="edyn.care" />
				<meta property="twitter:url" content="https://www.edyn.care/blog" />
				<meta name="twitter:title" content="Edyn Care | Blog" />
				<meta name="twitter:description" content="High quality care in the comfort of your own home." />
				<meta name="twitter:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png" />
			</Helmet>
			<div className="container">
				<Header />
				<main className="blog-landing-page">
					<div className="blog-landing">
						<div className="blog-landing-content">
							<div className="drop-down">
								<h1>Stories and news from the Edyn team and clients</h1>
							</div>
						</div>
						{/*<StaticImage placeholder="blurred"
							className="blog-landing-right"
							src="../../static/assets/marketing-site/images/blog-landing-texture.png"
						/>*/}
					</div>
					<NewsblockSection className="newsblock-latest">
						<NewsblockContent>
							<NewsblockTitle center displaySmall>
								An overview of Live-in care
							</NewsblockTitle>
							<NewsblockBlocks>
								{newsblockData.map(({ title, slug }) => (
									<Newsblock
										displayShort
										color="yellow"
										title={title}
										link={`/blog/${slug}/`}>
									</Newsblock>
								))}
							</NewsblockBlocks>
						</NewsblockContent>
					</NewsblockSection>
					<NewsblockSection className="newsblock-latest">
						<NewsblockContent>
							<NewsblockTitle center displaySmall>
								Our services
							</NewsblockTitle>
							<NewsblockBlocks>
								{newsblockData.map(({ title, slug }) => (
									<Newsblock
										displayShort
										color="yellow"
										title={title}
										link={`/blog/${slug}/`}>
									</Newsblock>
								))}
							</NewsblockBlocks>
						</NewsblockContent>
					</NewsblockSection>
					<Footer />
				</main>
			</div>
		</>
	);
}
